.login{
    background-color: #fff;
    
}
.loginContainer{
    max-width: 100% !important;
}
.loginLeft{
    /*border: 1px solid ;*/
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/Group\ 244.png');
    background-size: cover;
    filter:invert(47%) sepia(12%) saturate(4008%) hue-rotate(124deg) brightness(143%) contrast(102%)
}
.loginRight{
    /*border: 1px solid ;*/
    width: 100%;
    height: 670px;
    background-color: #fff;
    padding: 50px 90px;
    
}
.noPadding{
    padding: 0;
}
.loginRight .btn{
    background-color: #1D9598 !important;
}
.login .forgetP{
    text-align: center;
    color: #1D9598;
    font-weight: 500;
    font-size: 18px
}

