body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #90A4AE;
}
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
/* ///////////////////////////////////////info card///////////////////////////////////// */
.infoCard{
  border: 1px solid transparent;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  margin-top: 80px;
  margin-bottom: 40px;
}
.info h6{
  color: #999999 !important;
}
.infoCard > .btn.btn-block {
  margin: inherit;
  margin: 10px 15px !important;
  width: 92% !important;
  border-radius: 25px
}

.editCard{
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 400px;
  outline: none;
}
.editCard  .topIcon {
  color: #fff;
  padding-top: 28px;
  width: 80px;
  height: 80px;
  position: relative;
  margin-top: -6.6rem !important;
  margin-right: 4%;
  margin-left: 1.4rem;
  text-align: center;
  border-radius: 5px;
}
.editCard > .tableTitle {
  margin-top: -40px;
  
  font-size: 18px;
  font-weight: 400;
  color: #6f7884;
}
.addInput{
  width: 50%;
  border: #c9c6c6 dashed;
  height: 40px;
  text-align: center;
  padding: 5px;
  margin: auto;
  cursor: pointer;
}
.react-rater-star {
  cursor: pointer;
  color: #ccc;
  position: relative;
  font-size: 30px !important;
}
.react-rater-star.is-active {
  color: gold !important;
}
.ant-switch-checked {
  background-color: limegreen;
  margin-bottom: 10px;
}
.ant-switch::after {
  position: absolute;
  top: 0px;
  width: 38px;
  height: 38px;
}
.ant-switch-inner {
  display: block;
  margin-right: 6px;
  margin-left: 34px;
  color: #fff;
  font-size: 17px;
}
.ant-switch-checked .ant-switch-inner {
  margin-right: 24px;
  margin-left: -9px;
  margin-top: -5px;
  font-size: 17px;
}
.ant-switch{
  height: 40px;
  width: 90px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #fff !important;
  opacity: 1;
  cursor: not-allowed;
}
/********************************************ant select////////////////////////*/

.ant-select-tree-list-holder::-webkit-scrollbar {
  width: 14px !important;
}

.ant-select-tree-list-holder::-webkit-scrollbar-track {
  background: var(--scrollbarBG)  !important;
}
.ant-select-tree-list-holder::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG)  !important;
  border-radius: 6px !important;
  border: 3px solid var(--scrollbarBG) !important;
}
.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #fff;
  cursor: not-allowed;
  border: none;
  border-bottom: 1px solid #CED4DA;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
  color: #333 !important;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #fff;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #000;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 35px !important;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector{
  min-height: 35px !important;
}
.ant-image-mask .anticon{
  vertical-align:0 !important;
}
/********************************************upload////////////////////////*/
.fileUpload {
  background-color: #eeeeee;
  margin-top: 30px;
  height: 130px;
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2), 1px 1px 2px 0px rgba(0,0,0,0.14), 0px -1px 3px 0px rgba(0,0,0,0.12);
}
.upImgs {
  opacity: 0;
  position: relative;
  height: 130px;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
  float: left;
}
.camera{
  width: 130px;
  height: 90px;
  position: absolute;
  float: left;
  margin-left: -58%;
  margin-top: 1rem;
}
.btn.btn-block {
  margin: inherit;
  margin-bottom: 20px;
}
.preview{
  width: 100%;
  margin-top: 20px;
  float: left;
}
.preview img{
  width: 70px;
  height: 70px;
  margin: 10px;
}
.previewPdf{
  background-color: #eeeeee;
  height: 30px;
  padding:4px 6px 4px 15px;
  margin: 5px;
  border-radius: 18px;
  font-size: 14px;
  box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 20%), 1px 1px 2px 0px rgb(0 0 0 / 14%), 0px -1px 3px 0px rgb(0 0 0 / 12%);
  color: darkslateblue;
}
.removePdf{
  margin-left: 10px;
  color: red;
  cursor: pointer;
}
.removeImg5{
  top: 30px;
  position: relative;
  right: 5%;
  float: right;
  color: red;
  font-size: 16px;
  cursor: pointer;

}
.removeImg2{
  top: 132px;
  position: absolute;
  right: 6%;
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.imgName{
  font-size: 14px;
  font-weight: 400;
  color: black;
}
.previewImg2{
  width: 65px !important;
  height: 65px !important;
  margin: 7px !important;
}

.removeImg3{
  top: 165px !important;
  position: absolute;
  right: 6%;
  color: red;
  font-size: 16px;
  cursor: pointer;
}
.fileUpload2{
  background-color: transparent;
  margin-top: 30px;
  height: 50px;
  box-shadow: none;
  border: 1px solid #dedede;
}
.upImgs2{
  height: 50px;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 1000;
  cursor: pointer;
  float: left;
}
.uploadName{
  font-size: 13px;
  font-weight: 400;
  color: #000;
}
.uploadParent{
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  margin-top: 9px;
}
.addInputInner{
  background: green;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 4px 8px;
  border-radius: 20px;
  float: right;
  cursor: pointer;
  position: absolute;
  top: 35px;
}
.minusInput{
  background: red;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 6px 10px;
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  margin:0% 30%;
  top: 35px;
  font-size: 12px;
}
/****************************slider**********************/

.productSlider {
  padding: 10px;
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
}
.image-gallery {
  height: 100% !important;
}
.image-gallery-content {
  height: 100% !important;
}
.image-gallery-slide-wrapper {
  position: relative;
  height: 80% !important;
}
.image-gallery-swipe{
  height: 100% !important;
}
.image-gallery-slides, .image-gallery-image {

  height: 100% !important;
}
.image-gallery-slide.center {
  position: relative !important;
  height: 100% !important;
}
.image-gallery-play-button{
  display: none;
}
.image-gallery-right-nav , .image-gallery-left-nav{
  background: transparent;
  font-size: 30px !important;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 120px;
  width: 25px !important;
}
.image-gallery-right-nav:focus , 
.image-gallery-left-nav:focus , 
.image-gallery-fullscreen-button:focus{
  background: transparent;
  outline: none;
}
.fullscreen .bottom{
  height: 100% !important;
}
.image-gallery-thumbnail img {
  vertical-align: middle;
  width: 100% !important;
  height: 60px !important;
}
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(120vh - 80px);
  width: 100%;
}




.md-form>label{
  position: absolute;
  top: -30px !important;
  left: -2px !important;
}


.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}
.ant-message{
  z-index: 9999;
}

.myselect{
  margin-bottom: 16px;
  border: 0px;
  border-bottom: .5px solid #aaa;
  border-radius: 0px;
  outline: none;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #aaa;

}
.btnOption{
  border: 1px solid transparent;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  margin-top: 0px;
  margin-bottom: 100px;
  width: 100%;
  padding: 30px;
}
.tableTitle2 {
  margin-top: -20px;
  
  font-size: 18px;
  font-weight: 400;
  color: #6f7884;
  width: 100%;
  white-space: nowrap !important;
}
/****************************tabel**********************/
.screenTable{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.MuiFab-extended.MuiFab-sizeMedium {
  width: auto;
  height: 35px !important;
  padding: 0 16px;
  min-width: 40px;
  border-radius: 4px !important;
}


#components-layout-demo-responsive .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}
.infoTable{
  border: 1px solid transparent;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.14);
  margin-top: 80px;
  margin-bottom: 100px;
}
.topInfoTable {
  height: 90px;
  margin: auto;
  margin-top: -1.5rem;
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-bottom: 1rem;
}
.topInfoTable h4{
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 700;
  color:#fff
}
.infoTable > .tableTitle {
  margin-top: 10px;
  position: absolute;
  margin-left: 9.4rem;
  font-size: 20px;
  font-weight: 400;
  color: #6f7884;
}
/****************************search toggel**********************/
.showClass{
  min-height: 330px;
  transition: all 1s linear;
  margin: 20px 40px;
  overflow: hidden;
}
.showClass160{
  height: 160px;
  transition: all 1s linear;
  margin: 20px 40px;
  overflow: hidden;
}
.hideClass{
  height: 0;
  overflow: hidden;
  transition: all 1s linear;
  margin: 20px 40px
}
.searchIcon{
  transition: all .4s linear;
  font-size: 24px;
  color: #1D9598;
  border-radius: 50%;
  /* color: #fff; */
  cursor: pointer;
  padding: 8px;
  border: 2px solid #fff;
  margin: 4px;
  margin-right: 1px;
}
.searchIcon:hover{
  transition: all .4s linear;
  border-radius: 50%;
  padding: 8px;
  color: #1D9598;
  border: 2px solid #1D9598;
}


.needs-validation .md-form label {
  left: 0rem;
  top: -24px;
}

.ant-picker-range {
  width: 100% !important;
}
.react-tagsinput-tag {
  background-color: #1D9598 !important;
  border: 1px solid #1D9598 !important;
  color: #fff !important;
}
.react-tagsinput--focused {
  border-color: #ccc !important;
}
.md-form .form-control:disabled, .md-form .form-control[readonly] {
  background-color: initial;
  border-bottom: 1px solid #bdbdbd !important;
}

.tagsValues{
  font-size: 15px;
  border-radius: 4px;
  background: #1D9598;
  color: #fff;
  display: inline-block;
  padding: 2px 10px;
  margin: 2px 2px 4px;
  font-weight: 400;
  margin-bottom: 8px;
}
.labelBox{
  width:100%;
  margin:25px 14px;
}
.labelBox span{
  padding:10px;
  background:#1D9598;
  border-bottom-left-radius: 14px;
  color:#fff;
  margin-top:25px
}
.disabled, :disabled {
  pointer-events: all !important;
}
.form-control:disabled, .form-control[readonly] {
  cursor: auto !important;
}