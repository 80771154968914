

.ownerInfoCard{
    border: 0px solid transparent;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    margin-top: 80px;
    margin-bottom: 20px;
    background: #fff;
    /* background-image: url('../../assets/images/wave-41462.png');
    background-position: 20%; */
}
.ownerImg{
    height: 140px;
    width: 100%;
    text-align: center;
    padding: 15px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /*background-image: url('../../assets/images/space.png');*/
    background: #04926C;
}
.ownerImg img{
    height: 85px;
    width: 85px;
    border-radius: 50%;
    text-align: center;
    border: 2px solid #1D9598;
    margin: 75px;
}
.ownerInfo{
    text-align: center;
    margin-top: 40px;
}
.pName{
    margin-bottom: 2px;
    font-weight: 400;
    font-family: auto;
    font-size: 20px;
}
.pInfo{
    text-align: left;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
    margin-left: 5%;
    margin-right: 5%;
}
.pInfo span {
    top: 5px !important;
}
.infoIcon{
    font-size: 16px;
    position: relative;
    top: 2px;
    margin: 0px 6px;
    color: #1D9598;
}
.ownerInfoFooter{
    padding: 14px;
    border-top: 1px solid #dedede;
    margin: 14px;
}