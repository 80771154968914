.removeImg2{
    top: 132px;
    position: absolute;
    right: 6%;
    color: red;
    font-size: 16px;
    cursor: pointer;
}
.imgName{
    font-size: 14px;
    font-weight: 400;
    color: black;
}
.previewImg2{
    width: 65px !important;
    height: 65px !important;
    margin: 7px !important;
}


.fileUpload2{
    background-color: transparent;
    margin-top: 30px;
    height: 50px;
    box-shadow: none;
    border: 1px solid #dedede;
}
.upImgs2{
    height: 50px;
    opacity: 0;
    position: relative;
    width: 100%;
    z-index: 1000;
    cursor: pointer;
    float: left;
}
.uploadName{
    font-size: 13px;
    font-weight: 400;
    color: #000;
    font-family: auto;
}
.uploadParent{
    position: absolute;
    width: 100%;
    left: 0;
    text-align: center;
    margin-top: 9px;
}
.addInputInner{
    background: green;
    color: #fff;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 4px 8px;
    border-radius: 20px;
    float: right;
    cursor: pointer;
    position: absolute;
    top: 35px;
}
.minusInput{
    background: red;
    color: #fff;
    text-align: center;
    width: 30px;
    height: 30px;
    padding: 6px 10px;
    border-radius: 20px;
    cursor: pointer;
    position: absolute;
    margin:0% 30%;
    top: 35px;
    font-size: 12px;
}