.loading{
 border:'1px solid red';
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 30px;
 min-height: 400px
}

.topTable{
    border:1px solid #000000;
    height:150px;

}
.tableCard{
    margin-top: 50px;
    margin-bottom: 50px;
    min-height: 500px;
    outline: none;
}
.topIcon {
    color: #fff;
    padding-top: 28px;
    width: 80px;
    height: 80px;
    background: #1D9598;
    position: relative;
    margin-top: -3.3rem;
    margin-right: 4%;
    margin-left: 1.4rem;
    text-align: center;
    border-radius: 5px;
}
.tableTitle {
    margin-top: -40px;
    
    font-size: 16px;
    font-weight: 400;
    color: #6f7884;
}
.MuiPaginationItem-page.Mui-selected ,.MuiPaginationItem-page.Mui-selected:hover {
    background-color: #1D9598 !important;
    outline: none;
    color: #fff;
}
.MuiPaginationItem-page:hover{
    background-color:transparent !important
}
.MuiPaginationItem-page > .MuiPaginationItem-icon{
    display: none !important;
}

.pagenation{
    font-size: 12px !important;
    color: #a699b3 !important;
    cursor: pointer;
    border-radius: 15px !important;
    outline: none !important;
    font-weight: 300 !important;
    margin: 0px !important;
    width: 50px !important;
    margin-bottom: 10px !important;
}
.MuiPagination-root{
    margin-bottom: 10px !important;
}
.MuiPaginationItem-page.Mui-disabled {
    display: none;
}

.MuiInput-input:focus ,.MuiInput-input-input:hover {
    outline: 0;
    border-color: #1D9598 !important;
}
.MuiButtonBase-root {
    outline: none !important;
}



.MuiButtonBase-root:hover{
    background: #a699b3;
}

tbody:hover td  {
    cursor: pointer;
    
}
tbody:hover span{
    cursor: pointer;
    font-weight: bold;
   
}
tbody:hover tr:hover td {
	color: #444;
    font-weight: bold;
    font-size: 13px;
}
tbody:hover tr:hover span {
	color: #444;
    opacity: 1;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}
.ant-spin-dot-item {
    background-color: #1D9598;
}
.table .ant-image-mask {
    border-radius: 50%;
    
}
.table .ant-image-mask-info{
    color:transparent
}
.table .ant-image-mask .anticon{
    color: #fff;
    text-align: center;
    position: absolute;
    margin: 5px 16px;
}
.tag{
    margin-bottom: 0;
    background: #34BFA3;
    padding: 0px 0px 5px;
    border-radius: 16px;
    color: #fff;
    font-size: 14px;
    width: 80px;
    margin: auto;
}