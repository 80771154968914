.chat{
    background: #fff;
    margin-top: 1rem;
}
.inbox{
    min-height: 600px;
}
.topInbox{
    border-bottom: 1px solid #e4ecea;
    height: 80px;
    padding: 1rem;
}
.topInbox p{
    font-weight: 500;
    margin-top: 11px;
    text-align: right;
}
.bodyInbox{
    height: 450px;
    padding: 2rem;
    overflow-y: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #e4ecea;
}
.bodyInbox::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(207, 207, 207, 0);
}
  
.bodyInbox::-webkit-scrollbar-thumb {
   background: #90A4AE;
   border-radius: 5px;
}

.sender .senderName{
    margin-left: 16px;
    color: #999;
    font-size: 12px;
    margin-bottom: 2px;
    font-weight: 500;
}
.reciever .senderName{
    font-size: 12px;
    margin-bottom: 2px;
    font-weight: 500;
    margin-right: 16px;
    color: #999;
    text-align: right;
}

.message{
    display: inline-block;
    min-width: 80px;
    max-width: 500px;
    font-size: 12px;
    background: #edf2f4;
    border-radius: 18px;
    padding: 10px;
    margin-left: 1rem;
    min-height: 50px;
    text-align: right
   
}
.message p{
    margin-bottom: 0px;
    font-weight: 400;
    line-break: anywhere;
    word-wrap: break-word;
    font-size: 14px;
}
.msgTime{
    color: #999;
    font-size: 11px;
    font-weight: 400;
    margin-left: 1.1rem;
}

.reciever .message{
    float: right;
    margin-right: 1rem;
    background: #1D9598;
    color: #fff;
    border-bottom-right-radius: 0px;
    border-top-right-radius:0px
}
.sender .message{
    background: #F0F2F5;
    color: #333;
    border-bottom-left-radius: 0px;
    border-top-left-radius:0px
}
.reciever .msgTime{
    color: #999;
    padding-right: 2.4rem;
    text-align: right;
    width: 100%;
    padding-top: 50px;
}
.reciever , .sender{
    width: 100%;
    display: inline-block
}

@media (max-width: 1200px){
    
    .message {
        display: inline-block;
        min-width: 341px;
        max-width: 400px;
    }
}

@media (max-width: 767px){
    .message{
        max-width: 50px;
    }
    
}
@media (max-width: 543px){
    .message{
        min-width: 300px;
    }
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1200px;
    }
}
@media (max-width: 480px){
    .message {
        min-width: 260px;
    }
    .topInbox {
        padding: 1rem .5rem;
    }
}
@media (max-width: 440px){
    .message {
        min-width: 200px;
    }
    .topInbox p {
        display: inline-block;
        font-weight: 400;
        margin-top: .8rem;
        margin-left: 0rem !important;
    }
}
@media (max-width: 360px){
    .message {
        min-width: 140px;
    }
}
